import React from 'react'

export default function Nopage() {
    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, W don't have this page.</p>

        </div>
    )
}
